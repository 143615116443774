import { lazy } from "react";

const HOME = lazy(() => import("../views/home"));
const CONTACT_US = lazy(() => import("../views/contactUs"));
const DONATE = lazy(() => import("../views/donate"));

const APP_ROUTES = [
  { path: "/", name: "Home", component: HOME },
  { path: "/contactUs", name: "contact us", component: CONTACT_US },
  { path: "/donate", name: "donate", component: DONATE },
];

export default APP_ROUTES;
