import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import routes from "./routes";
import { Suspense, useEffect } from "react";


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const generateRoutes = (routeList) => {
    return routeList.map(({ path, component: Component }, index) => {
      return <Route key={index} path={path} element={<Component />} />;
    });
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <ScrollToTop />
        <Routes>{generateRoutes(routes)}</Routes>
      </Router>
    </Suspense>
  );
}

export default App;
